var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 pt-4 px-0"},[_c('tabla-general',{attrs:{"data":_vm.clientes,"columnas":_vm.dataColumnas,"usar-paginacion":"","alto":_vm.$route.name == 'admin.cedis.cupones.ver.clientes-especificos' ? 'calc(100vh - 410px)' : 'calc(100vh - 284px)'}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto text-general text-center f-16 f-600"},[_vm._v(" "+_vm._s(_vm.datosBasicos.destinatario == 1 ? _vm.$config.cliente : _vm.$config.vendedor)+" que pueden hacer efectivo el cupón ")])]),_c('template',{slot:"adicionales-derecha"},[_c('el-table-column',{attrs:{"label":"Cupón aplicado","width":"180","class-name":"text-general f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"text-general f-18"},[_vm._v(" "+_vm._s(row.padre.numerador)+" / "+_vm._s(row.padre.denominador)+" "),_c('el-tooltip',{attrs:{"placement":"bottom","content":"Buscar clientes por cliterios","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"mb-2"},[_vm._v(" Vigencia:"+_vm._s(_vm._f("helper-fecha")(row.padre.fecha_vigencia,'DD MMM YYYY'))+" ")]),_vm._v(" Aplicado: "),_vm._l((row.padre.fechas_usos),function(f,idx){return _c('p',{key:idx},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(f,'DD MMM YYYY'))+" ")])})],2),(row.padre.icono == 2)?_c('i',{staticClass:"icon-cupon f-18 text-general2"}):_vm._e(),(row.padre.icono == 3)?_c('i',{staticClass:"icon-cupon f-18 text-general"}):_vm._e(),(row.padre.icono == 1)?_c('i',{staticClass:"icon-cupon f-18 text-general-red"}):_vm._e()])],1)])])]}}])}),_c('el-table-column',{attrs:{"label":"Fecha de uso","width":"180","class-name":"text-general f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('span',{staticClass:"text-general f-18"},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.padre.ultimo_uso,"DD-MMM-YYYY"))+" ")])])]}}])}),(_vm.datosBasicos.tipo == 2)?_c('el-table-column',{attrs:{"label":"Bono aplicado","width":"180","class-name":"text-general f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.hijo.denominador != null)?_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"text-general f-18"},[_vm._v(" "+_vm._s(row.hijo.numerador)+" / "+_vm._s(row.hijo.denominador)+" "),_c('el-tooltip',{attrs:{"placement":"bottom","content":("Buscar " + (_vm.$config.cliente + 's') + " por cliterios"),"effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"mb-2"},[_vm._v(" Vigencia:"+_vm._s(_vm._f("helper-fecha")(row.hijo.fecha_vigencia,'DD MMM YYYY'))+" ")]),_vm._v(" Aplicado: "),_vm._l((row.hijo.fechas_usos),function(f,idx){return _c('p',{key:idx},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(f,'DD MMM YYYY'))+" ")])})],2),(row.hijo.icono == 2)?_c('i',{staticClass:"icon-cupon f-18 text-general2"}):_vm._e(),(row.hijo.icono == 3)?_c('i',{staticClass:"icon-cupon f-18 text-general"}):_vm._e(),(row.hijo.icono == 1)?_c('i',{staticClass:"icon-cupon f-18 text-general-red"}):_vm._e()])],1)])]):_c('div',{staticClass:"row mx-0 justify-center"},[_vm._v(" -- ")])]}}],null,false,4245318061)}):_vm._e(),(_vm.datosBasicos.tipo == 2)?_c('el-table-column',{attrs:{"prop":"fecha_uso","label":"Fecha de uso","width":"180","class-name":"text-general f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('span',{staticClass:"text-general f-18"},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.hijo.ultimo_uso,"DD-MMM-YYYY"))+" ")])])]}}],null,false,365709830)}):_vm._e()],1)],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }